.signature-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  }

  .signature-canvas {
    border: 1px solid #ccc;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .button-container {
    display: flex;
    gap: 10px;
  }
  
  .copy-button, .save-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
  }
  

  
  .save-button {
    background-color: #27ae60; /* Green */
  }

  .copy-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    background-color: #3498db; /* Blue */
    margin-right: 10px;
  }  

  .clipboard-message {
    margin-top: 10px;
    font-size: 14px;
    color: #3498db; /* Blue */
  }



  .color-buttons { 
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .color-button {
    position: relative;
    width: 16px;
    height: 16px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
  }
  

.red {
  background-color: red;
}

.blue {
  background-color: blue;
}

.black {
  background-color: black;
}


.check-mark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.clear-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #3498db; /* Blue */
  font-size: 24px;
}







